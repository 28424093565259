<template>
  <v-container
    id="panels"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        class="mx-auto"
        cols="12"
        md="12"
      >
      
        <base-material-card
          icon="mdi-clipboard-text"
          title="List Data Perjadin"
          class="px-5 py-3"
        >

          <v-expansion-panels focusable>
            <v-expansion-panel
              key="1"
              class="px-5 py-5"
            >
              <v-expansion-panel-header>Klik disini untuk melihat data status pembayaran perjadin</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col>
                    <v-dialog
                        ref="SelYear"
                        v-model="menuSelYear"
                        :return-value.sync="selectedYear"
                        persistent
                        width="290px"
                      >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="selectedYear"
                          label="Pilih Tahun"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="selectedYear" type="month" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menuSelYear = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.SelYear.save(selectedYear)">OK</v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col>
                  <v-btn color="blue" @click="getList()">Show Data</v-btn>
                  </v-col>
                  <v-col class="text-right">
                    &nbsp;
                  </v-col>
                </v-row>

                <v-data-table
                    :headers="headers"
                    :items="listData"
                    :items-per-page="15"
                    class="elevation-1"
                    :server-items-length="totalData"
                    :loading="listLoading"
                    :options.sync="tableOpt"
                  >
                    <template v-slot:item.sts_payment="{ item }">
                      <span class="subtitle-2">{{item.sts_payment}}</span>
                    </template>

                    <template v-slot:item.spp="{ item }">
                      <span v-html="item.documentno"></span>
                      <br><br><span style="font-size:11px">{{item.tanggal_spp | formatDate}}</span>
                    </template>

                    <template v-slot:item.spm="{ item }">
                      <span v-html="item.id_spm"></span>
                      <br><br><span style="font-size:11px">{{item.tanggal_spm | formatDate}}</span>
                    </template>

                    <template v-slot:item.payment="{ item }">
                      <span v-html="item.pay_documentno"></span>
                      <br><br><span style="font-size:11px">{{item.tgl_pay | formatDate}}</span>
                    </template>
                  
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          
        </base-material-card>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="toast.show"
      :timeout="toast.timeout"
    >
      {{ toast.text }}
      <v-btn
        :color="toast.color"
        text
        @click="toast.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="isLoading" fullscreen>
      <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
          <v-layout justify-center align-center>
            <v-card
              width="70vW"
              class="pa-md-2 mx-lg-auto"
              outlined
            >
              <v-list-item three-line centered>
                <v-list-item-content>
                  <div class="overline mb-4">Processing</div>
                  <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-progress-linear
                      indeterminate
                      color="yellow darken-2"
                    ></v-progress-linear>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>

          </v-layout>
      </v-container>
    </v-dialog>

  </v-container>
</template>

<script>
  import secureStorage from '@/utils/secureStorage'
  import moment from 'moment'
  import { countStsSPD_Idempiere, getStsSPD_Idempiere } from '@/api/admin'
  import formatCurrency from 'format-currency'
  import { _GLOBAL } from '@/api/server'

  export default {
    name: 'FinancePerjadin',

    data: () => ({
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'No. SPP', value: 'spp', align: 'center', class:'subtitle-1' },
        { text: 'No. SPM', value: 'spm', align: 'center', class:'subtitle-1' },
        { text: 'No. Payment', value: 'payment', align: 'center', class:'subtitle-1' },
        { text: 'STATUS', value: 'sts_payment', align: 'center', class:'subtitle-2' },
        { text: 'Nama', value: 'nama', align: 'center', class:'subtitle-1' },
        { text: 'No. Rekening', value: 'no_rekening', align: 'center', class:'subtitle-1' },
        { text: 'Deskripsi', width: '300px', value: 'description', align: 'center', class:'subtitle-1' },
        { text: 'Nilai Pembayaran (Rp.)', value: 'netto_pembayaran', align: 'center', class:'subtitle-1' },
      ],
      isLoading: false,
      selectedYear: new Date().toISOString().substr(0, 7),
      menuSelYear: false,
      listData: [],
      totalData: 0,
      listLoading: false,
      tableOpt: {},
      listQuery: {
        page: 1,
        limit: 20,
        search: '',
        thang: ''
      }
    }),
    filters: {
      formatDate: function (value) {
        if (!value) return '-'
        var sdate = moment(value).format('DD MMM YYYY')
        return sdate
      }
    },
    computed: {
    },
    created () {
      const vm = this

      vm.dataUser = secureStorage.getItem('user')
      if (vm.dataUser !== null) {
        // console.log(vm.dataUser)
        if(vm.dataUser.role !== null) {
          if(vm.dataUser.role.id === 1) {
            vm.isAdmin = true
          }
        }
      }
    },
    destroyed () {
    },
    mounted () {
      const vm = this
    },
    watch: {
      tableOpt: {
        handler () {
          this.getList()
        },
        deep: true,
      },
    },
    methods: {
      goFileLink (uri) {
        window.open(uri, '_blank');
      },
      async getList(queryInfo) {
        var vm = this
        const { sortBy, sortDesc, page, itemsPerPage } = vm.tableOpt
        vm.listQuery.page = page
        vm.listQuery.limit = itemsPerPage
        vm.listQuery.limit = itemsPerPage == -1 ? vm.totalData : itemsPerPage
        // console.log('listQuery: ',vm.listQuery)
        vm.listQuery.thang = moment(vm.selectedYear).format('YYYY')
        // console.log(queryInfo)
        // get total data
        vm.isLoading = true
        countStsSPD_Idempiere(vm.dataUser.fullname, vm.listQuery).then(response => {
          // console.log('countStsSPD_Idempiere', response.data)
          var total = response.data.total
          vm.totalData = parseInt(total)
          vm.isLoading = false
        })
        // get list of data
        vm.listLoading = true
        getStsSPD_Idempiere(vm.dataUser.fullname, vm.listQuery).then(response => {
          // console.log('getStsSPD_Idempiere', response.data)
          var array = response.data
          let opts = { format: '%s%v', symbol: 'Rp. ' }

          if(array != null){
            // console.log(array)
            array.forEach(element => {

              if(element.id_spp == null){
                element.documentno = '-'
              }else{
                element.documentno = element.id_spp
                if(element.id_spp.startsWith("SPBA")){
                  element.pay_documentno = element.id_payment_dp
                  if(element.tgl_payment_dp != null){
                    element.tgl_pay = moment(element.tgl_payment_dp).format('YYYY-MM-DD')
                  }else{
                    element.tgl_pay = ''
                  }
                }else{
                  element.pay_documentno = element.id_payment
                  if(element.tgl_payment != null){
                    element.tgl_pay = moment(element.tgl_payment).format('YYYY-MM-DD')
                  }else{
                    element.tgl_pay = ''
                  }
                }
              }

              if(element.tgl_spm != null){
                element.tanggal_spm  = moment(element.tgl_spm).format('YYYY-MM-DD')
              }else{
                element.tanggal_spm  = ''
              }

              if(element.tanggal_spp != null){
                element.tanggal_spp  = moment(element.tanggal_spp).format('YYYY-MM-DD')
              }else{
                element.tanggal_spp  = ''
              }
            
              if(element.inv_documentno == null){
                element.inv_documentno = '-'
              }else{
                element.inv_documentno = element.id_spm
              }

              element.sts_payment = '-'
              if(element.pay_documentno == null){
                element.pay_documentno = '-'
                element.sts_payment = 'PROSES PEMBAYARAN'
                element.sort = 1 
              }else{
                element.sts_payment = 'DISETUJUI BENDAHARA' 
                element.sort = 2 
              }
              
              element.netto_pembayaran = formatCurrency(element.netto_pembayaran, opts)

            });
          }

          if (sortBy && sortDesc && sortBy.length === 1 && sortDesc.length === 1) {
            array = array.sort((a, b) => {
              const sortA = a[sortBy[0]]
              const sortB = b[sortBy[0]]

              if (sortDesc[0]) {
                if (sortA < sortB) return 1
                if (sortA > sortB) return -1
                return 0
              } else {
                if (sortA < sortB) return -1
                if (sortA > sortB) return 1
                return 0
              }
            })
          }
          
          vm.listData = array
          vm.listLoading = false
        })
      },
    }
  }
</script>
